<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              title="Competency"
              :data-source="dataSource"
              :total-count="totalCount"
              @on-delete="(v) => onDelete(v)"
              @on-open-modal="(v) => onManage(v)"
              @on-update-cancel="(v) => loadCompetencies()"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
            >
              <template #columns>
                <DxColumn data-field="name" caption="Name" />
                <DxColumn data-field="description" caption="Description" />
                <DxColumn data-field="skill_level" caption="Level" />
                <DxColumn data-field="skill_category" caption="Category" />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="onShowModal"
      centered
      @hide="onCloseModal()"
      size="lg"
      no-close-on-backdrop
    >
      <b-row>
        <b-col>
          <b-form-group label="ID" label-for="v-id">
            <b-form-input v-model="formData.competency_id" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Name" label-for="v-name">
            <b-form-input v-model="formData.name" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Skill Category" label-for="v-skill_category">
            <b-form-input v-model="formData.skill_category" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Skill Level" label-for="v-skill_level">
            <b-form-input v-model="formData.skill_level" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Description" label-for="v-description">
            <b-form-textarea v-model="formData.description" />
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button
          size="sm"
          @click="onCloseModal()"
          variant="danger"
          class="bg-darken-4"
        >
          Cancel
        </b-button>
        <b-button
          size="sm"
          @click="onSubmit()"
          variant="primary"
          class="bg-darken-4"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import competencyApi from '@api/competencies';
import { queryParameters } from '@/schema';

export default {
  name: 'CompetencyPage',
  components: {},
  data: () => ({
    onShowModal: false,
    totalCount: 0,
    page: 0,
    size: 0,
    dataSource: [],
    formData: {
      id: 0,
      competency_id: null,
      name: null,
      description: null,
      skill_category: null,
      skill_level: null,
    },
  }),
  watch: {
    page(v) {
      this.loadCompetencies();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadCompetencies();
      }
    },
  },
  mounted() {
    this.loadCompetencies();
  },
  methods: {
    loadCompetencies() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      competencyApi
        .list(params)
        .then(({ data, meta }) => {
          this.dataSource = data;
          this.totalCount = meta.page.total;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      const onSuccess = () => {
        this.loadCompetencies();
        this.onCloseModal();
      };

      if (!this.formData.id) {
        competencyApi
          .add(this.formData)
          .then(({ data }) => {
            onSuccess();
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });

        return;
      }

      competencyApi
        .update(this.formData)
        .then(({ data }) => {
          onSuccess();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onManage(event) {
      this.onShowModal = true;

      if (event.name === 'edit') {
        this.formData = event.data;
      }
    },
    onDelete(event) {
      competencyApi
        .delete(event)
        .then(({ data }) => {
          this.loadModules();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onCloseModal() {
      this.onShowModal = false;
      this.formData = {
        id: 0,
        competency_id: null,
        name: null,
        description: null,
        skill_category: null,
        skill_level: null,
      };
    },
  },
};
</script>

<style></style>
