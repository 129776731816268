import api from '@apiInstance';

export default {
  add(data) {
    return api.post('/competencies', data);
  },
  get(id) {
    return api.fetch('/competencies/' + id);
  },
  list(params) {
    return api.fetch('/competencies', { params });
  },
  update(data) {
    return api.patch('/competencies', data);
  },
  delete(id) {
    return api.remove('/competencies', id);
  },
};
